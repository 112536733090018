/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import TextShell from 'components/flex/Columns/Text/TextShell'
import ImageShell from 'components/flex/Columns/Image/ImageShell'
import BlockShell from 'components/flex/Columns/Blocks/BlockShell'

interface ColumnsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns
  location?: any
}

interface ComponentProps {
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

const Columns: React.FC<ColumnsProps> = ({ fields, location }) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    text: TextShell,
    image: ImageShell,
    blocks: BlockShell,
  }

  const flexersFields: FlexerProps = {
    text: 'textgroup',
    image: 'imagegroup',
    blocks: 'blockgroup',
  }

  if (!fields.columns) {
    return null
  }

  const shouldReverseOnMobile = fields.columns[0]?.styletype === 'image'

  return (
    <section id={fields.webid} className="position-relative">
      <div className="container py-lg-3">
        <div
          className={`row py-lg-5 py-3 justify-content-${fields?.alignment} ${
            shouldReverseOnMobile ? 'flex-column-reverse flex-sm-row' : ''
          }`}
        >
          {fields.columns?.map((column, index) => {
            if (!column?.styletype || !flexers[column?.styletype]) {
              return null
            }

            const Component: React.FC<ComponentProps> =
              flexers[column.styletype]

            return (
              <div
                key={`${location ? location.pathname : 'column-flex'}-${index}`}
                className={`col-lg-${column.width} my-lg-2 my-3`}
              >
                <Component
                  // @ts-ignore
                  fields={column[flexersFields[column.styletype]]}
                  location={location}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Columns
