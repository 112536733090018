import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Content = styled(ParseContent)`
  @media screen and (max-width: 1199px) {
    & h2 {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: 400px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
      padding-bottom: 5px;
    }
  }
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <section className="d-flex align-items-center h-100">
    <Content content={fields.description || ''} />
  </section>
)

export default Text
