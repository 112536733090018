import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface OffscreenProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns_columns_Imagegroup
}

const StyledPlaatjie = styled(Plaatjie)`
  bottom: 0;
  right: 0;
  /* position: absolute !important; */
  pointer-events: none;
  height: 100%;
  width: 100%;
  max-width: 1000px;

  & img {
    object-fit: contain !important;
  }

  @media (max-width: 1439px) {
    /* opacity: 0.3; */
  }
`

const Offscreen: React.FC<OffscreenProps> = ({ fields }) => (
  <section>
    <StyledPlaatjie
      image={fields.image}
      alt=""
      style={{ objectFit: 'contain' }}
    />
  </section>
)

export default Offscreen
