import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

interface GalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns_columns_Imagegroup
}

const ImageContainer = styled(motion.div)`
  overflow: hidden;
  max-height: 215px;

  @media screen and (max-width: 1199px) {
    height: 235px;
    width: 100%;
  }

  @media (max-width: 575px) {
    height: 175px;
    width: 100%;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  overflow: hidden;
  max-height: 215px;

  @media screen and (max-width: 1199px) {
    height: 235px;
    width: 100%;
  }

  @media (max-width: 575px) {
    height: 175px;
    width: 100%;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-gap: 12px;

  @media screen and (max-width: 991px) {
    grid-gap: 6px;
    margin-left: -6px;
    /* margin-right: -3px; */
  }
`

const Shadow = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(248, 203, 70, 0.7);
`

const ContentContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: 24.52px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
      line-height: ${({ theme }) => theme.font.size.small};
    }
  }

  & h3 {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.55);
    padding-bottom: 10px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 10px;

  & a {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.55);
  }

  & svg {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.55));
  }

  &:hover {
    & a {
      text-shadow: none;
    }

    & svg {
      filter: none;
    }
  }
`

const Gallery: React.FC<GalleryProps> = ({ fields }) => {
  const [hoverIndex, setHoverIndex] = React.useState(3)

  return (
    <div className="h-100 d-flex align-items-center">
      <section className="d-flex aling-items-center">
        <Container className="mt-3 mt-lg-0">
          {fields.gallery?.map((image, index) => (
            <React.Fragment key={index}>
              {image?.case === 'yes' ? (
                <ImageContainer
                  onHoverStart={() => setHoverIndex(index)}
                  onHoverEnd={() => setHoverIndex(index)}
                  onTapStart={() => setHoverIndex(index)}
                  className="position-relative"
                >
                  <AnimatePresence>
                    {hoverIndex === index && (
                      <>
                        <Shadow
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        />
                        <ContentContainer
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="d-flex align-items-center p-2 p-sm-5"
                        >
                          <Content content={image.description || ''} />
                          <StyledButton
                            className="light transparent"
                            arrow
                            to={image.link?.url || '/'}
                          >
                            {image.link?.title}
                          </StyledButton>
                        </ContentContainer>
                      </>
                    )}
                  </AnimatePresence>
                  <motion.div
                    animate={
                      hoverIndex === index
                        ? { filter: 'grayscale(100%)' }
                        : { filter: 'grayscale(0%)' }
                    }
                  >
                    <StyledPlaatjie
                      image={image?.image}
                      loading="eager"
                      alt=""
                    />
                  </motion.div>
                </ImageContainer>
              ) : (
                <Plaatjie image={image?.image} loading="eager" alt="" />
              )}
            </React.Fragment>
          ))}
        </Container>
      </section>
    </div>
  )
}

export default Gallery
