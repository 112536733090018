import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface ImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns_columns_Imagegroup
}

const StyledPlaatjie = styled(Plaatjie)`
  pointer-events: none;
  height: 100%;
  width: 100%;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`

const Image: React.FC<ImageProps> = ({ fields }) => (
  <section className="d-flex align-items-center h-100">
    <StyledPlaatjie image={fields.image} alt="" />
  </section>
)

export default Image
