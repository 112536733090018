import React from 'react'

// Components
import Gallery from 'components/flex/Columns/Image/Gallery'
import Image from 'components/flex/Columns/Image/Image'
import Offscreen from 'components/flex/Columns/Image/Offscreen'

interface ImageShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns_columns_Imagegroup
  location?: any
}

interface PostProps {
  [key: string]: any
}

const ImageShell: React.FC<ImageShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    gallery: Gallery,
    normal: Image,
    offscreen: Offscreen,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'image-flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ImageShell
