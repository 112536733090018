import React from 'react'

// Components
import Normal from 'components/flex/Columns/Blocks/Normal'

interface BlockShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns_columns_Textgroup
  location?: any
}

interface PostProps {
  [key: string]: any
}

const BlockShell: React.FC<BlockShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    normal: Normal,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'text-flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BlockShell
