/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'

interface NormalProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Columns_columns_Blockgroup
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-gap: 20px;
  @media (max-width: 575px) {
    grid-template-columns: 100% 100%;
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding-top: 100%;
  position: relative;
`

const ContentWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;

  @media (max-width: 1199px) {
    padding: 20px;

    & p {
      line-height: 24px;
    }
  }

  @media screen and (max-width: 575px) {
    & h5 {
      font-size: 30px;
      padding-bottom: 10px;
    }

    & p {
      font-size: 18px;
    }

    & a {
      font-size: 18px;

      & svg {
        width: 18px;
        height: 14px;
        margin-top: 3px;
      }
    }
  }
`

const Content = styled(ParseContent)`
  & h3,
  h4,
  h5,
  h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: 30.65px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.regular};
      line-height: ${({ theme }) => theme.font.size.regular};
    }
  }
`

const Normal: React.FC<NormalProps> = ({ fields }) => (
  <section>
    <Container>
      {fields.blocks?.map((block, index) => (
        <Block key={index}>
          <ContentWrapper>
            <div>
              <Content content={block?.description || ''} />
              <div className="d-flex justify-content-center">
                <Button
                  className="mt-xl-4 primary transparent"
                  arrow
                  to={block?.link?.url || '/'}
                >
                  {block?.link?.title}
                </Button>
              </div>
            </div>
          </ContentWrapper>
        </Block>
      ))}
    </Container>
  </section>
)

export default Normal
